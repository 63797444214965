
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import type { NextPage } from "next";
import Profile from "../../src/pages/Stream/Profile";
export default Profile as NextPage;

    async function __Next_Translate__getServerSideProps__19195526eb1__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/stream/[id]',
            loaderName: 'getServerSideProps',
            
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19195526eb1__ as getServerSideProps }
  